import { Injectable } from '@angular/core';
import mixpanel, { Dict } from 'mixpanel-browser';
import { apiUrls } from '../../../../environments/environments';
import { User } from '../../entities/user';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor() {}

  init(): void {
    mixpanel.init(apiUrls.mixpanelToken, {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage',
    });
  }

  identify(user: User): void {
    mixpanel.identify(user.id);
    this.updateUser(user);
  }

  updateUser(user: User): void {
    mixpanel.people.set({
      $name: user.name,
      $email: user.email,
      superuser: user.superuser,
      theme: user.favoriteColor,
    });
  }

  track(event: string, data: Dict): void {
    mixpanel.track(event, data);
  }
}
